import { render, staticRenderFns } from "./medicine_data.vue?vue&type=template&id=2537706a&scoped=true"
import script from "./medicine_data.vue?vue&type=script&lang=js"
export * from "./medicine_data.vue?vue&type=script&lang=js"
import style0 from "./medicine_data.vue?vue&type=style&index=0&id=2537706a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2537706a",
  null
  
)

export default component.exports