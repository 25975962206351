<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_medicine_data_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"></mobileTab>
    <!-- 商业智能BI-SMAS -->
    <div class="mobile-bi-box" v-show="currentIndex == 0">
      <div class="mobile-bi-list" v-for="(item, index) in tabList1" :key="index">
        <div class="mobile-bi-title">{{ item.title }}</div>
        <p>{{ item.txt }}</p>
        <mobileImg :url="item.url" boxHeight="8.52rem"></mobileImg>
      </div>
    </div>
    <!-- 智能算法 -->
    <div class="mobile-bi-box" v-show="currentIndex == 1">
      <div class="mobile-bi-list" v-for="(item, index) in tabList2" :key="index">
        <div class="mobile-bi-title">{{ item.title }}</div>
        <p>{{ item.txt }}</p>
        <mobileImg :url="item.url" :imgWidth="item.width || ''" :boxHeight="item.width?'':item.boxHeight" backColor="#fafafa" paddingBox=".3rem" :border="item.width?'':'.04rem solid #EBEBEB'"></mobileImg>
      </div>
    </div>
    <!-- 大数据报告 -->
    <div class="mobile-dsj-box" v-show="currentIndex == 2">
      <mobileTitle titleZh="智能化、自动化" titleEn="系统通过智能数据分析，自动生成数据报告，定向推送随时下载" widthEn="6.06rem" fontSizeEn=".28rem" colorEn="#808080" lineEn=".5rem" marginTopZh=".78rem" marginBottomZh="1.06rem" marginTopEn=".4rem"></mobileTitle>
      <mobileImg :url="dsjImg"  boxHeight="4.44rem" backColor="#fafafa" marginBox="0 .4rem" border=".04rem solid #EBEBEB"></mobileImg>
      <mobileTitle titleZh="自然语言NLP" titleEn="运用自然语言NPL技术无需人工干预，根据数据结果生成高阅读性、高质量的数据报告" widthEn="6.06rem" fontSizeEn=".28rem" colorEn="#808080" lineEn=".5rem" marginTopZh=".78rem" marginBottomZh="1.06rem" marginTopEn=".4rem"></mobileTitle>
      <img class="w100f" src="../../../assets/mobile_images/mo_medicine_data_dsj2.png" alt="">
      <mobileTitle titleZh="数据融合" titleEn="高度融合自定义数据处理模块，生成数、表、图，无需人工干预" widthEn="6.06rem" fontSizeEn=".28rem" colorEn="#808080" lineEn=".5rem" marginTopZh=".78rem" marginBottomZh="1.06rem" marginTopEn=".4rem"></mobileTitle>
      <img class="w100f" src="../../../assets/mobile_images/mo_medicine_data_dsj3.png" alt="">
      <mobileTitle titleZh="一客一例" titleEn="可按照客户的要求及习惯个性化定义数据报告样式，真正做到一客一例" widthEn="6.06rem" fontSizeEn=".28rem" colorEn="#808080" lineEn=".5rem" marginTopZh=".78rem" marginBottomZh="1.06rem" marginTopEn=".4rem"></mobileTitle>
      <img class="w100f" src="../../../assets/mobile_images/mo_medicine_data_dsj4.png" alt="">
    </div>
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      dsjImg:require('../../../assets/mobile_images/mo_medicine_data_dsj1.gif'),
      currentIndex: 0,
      tabs: [{ title: '商业智能BI-SMAS' }, { title: '智能算法' }, { title: '大数据报告' }],
      tabList1: [
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_bi1.png'),
          title: '轻配置',
          txt: '只需要编写简单的sql语句，简单的勾选需展示可视化图表即可轻松完成配置。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_bi2.png'),
          title: '多维度',
          txt: '支持多维度的报表查询，同时也支持常用的筛选进行多维度的查询报表。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_bi3.png'),
          title: '简单操作',
          txt: '简单的点击或者输入，即可对报表进行查询，同时支持不同展示方式的钻取，方便快捷。',
        },
        // {
        //   url: require('../../../assets/mobile_images/mo_medicine_data_bi4.png'),
        //   title: '易查询',
        //   txt: '简单的点击或者输入，即可对报表进行查询，同时支持不同展示方式的钻取，方便快捷。',
        // },
      ],
      tabList2: [
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_znsf1.png'),
          title: '智能派单算法',
          txt: '根据每个省市区业务员的基础信息数据、行为数据、销售数据以及公司的目标值，对业务员推送精准的客户类型以及对应的拜访数。',
          boxHeight:"10.38rem",
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_znsf2.png'),
          title: '选仓算法',
          txt: '根据客户的需求数据(即销量数据)以及工厂到各个客户的时间要求、成本要求以及紧急程度等，以时间成本优先原则，利用重心法、P中值等选仓模型选择合适的仓库位置，实现多方共赢。',
          width:"100%"
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_znsf3.png'),
          title: '选品算法',
          txt: '利用仓库发货数据，根据货品的需求频率和需求稳定度两个维度来进行选品分析，选择需求频率高、需求稳定度高的短尾产品实现前置仓的周转率，减少需求频率极低、需求稳定度不高的长尾产品，降低库存成本。',
          boxHeight:"4.75rem",
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_data_znsf4.png'),
          title: '销售预测算法',
          txt: '根据以往的销售数据，通过观察数据的趋势、周期、季节性等因素，利用ARIMA模型预测未来周期的销售数据，以便于仓库的补货。',
          boxHeight:"6.86rem",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  //BI
  .mobile-bi-box {
    padding: 0 0.4rem;
    box-sizing: border-box;
    .mobile-bi-list {
      text-align: left;
      margin-top: 0.7rem;
      .mobile-bi-title {
        font-size: 0.52rem;
        color: #4fa1a4;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 0.28rem;
        color: #3d3d3d;
        line-height: 0.5rem;
        margin-bottom: 0.28rem;
        text-indent: 2em;
      }
      // .mobile-bi-img{
      //   height: 8.52rem;
      //   overflow-y: hidden;
      //   overflow-x: auto;
      //   img{
      //     height: 8.52rem;
      //   }
      // }
    }
  }
  .mobile-grey-box {
    width: 100%;
    height: 8.54rem;
    padding: 0.4rem;
    background: #f7f7f7;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    img {
      height: 7.74rem;
    }
  }
}
</style>
