<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/medicine_data_bg.jpg" alt="" />
        <div class="bg-text">
          <!-- <div class="bg-text-left">
          <p class="font-title">数据产品</p>
          <div class="font-title-txt1">灵活可配置　|　多维度分析　|　可视化输出</div>
          <div class="font-title-txt">
            从业务的角度出发，对数据进行整合及处理，配置不同维度下的可视化报表，支<br />持报表及报告的自定义配置及输出。同时报表支持不同端口的系统集成。
          </div>
          <div class="next-btn mt60">进一步了解</div>
        </div> -->
          <!-- <div class="bg-text-right">
          <p class="font-one">Technology leads transform, Data drives serviceValue</p>
          <p class="font-two">Transparent Word</p>
        </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h94"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"> </Tab>
        <div class="h46"></div>
        <!-- 商业智能BI-SMAS -->
        <div class="w100f" v-show="currentIndex == 0">
          <div class="img-and-txt">
            <div class="img-and-txt-left">
              <div class="img-and-txt-left-top">轻配置</div>
              <div class="img-and-txt-left-bottom">
                只需要编写简单的sql语句，简单的勾选需展示可视化图表即可轻松完成配置。
              </div>
            </div>
            <div class="img-and-txt-right">
              <img width="89%" src="../../assets/images/medicine_data_a1.png" alt="" />
            </div>
          </div>
          <div class="h76"></div>
          <div class="img-and-txt">
            <div class="img-and-txt-left">
              <div class="img-and-txt-left-top">多维度</div>
              <div class="img-and-txt-left-bottom">
                支持多维度的报表查询，同时也支持常用的筛选进行多维度的查询报表。
              </div>
            </div>
            <div class="img-and-txt-right">
              <img width="89%" src="../../assets/images/medicine_data_a2.png" alt="" />
            </div>
          </div>
          <div class="h76"></div>
          <div class="img-and-txt">
            <div class="img-and-txt-left">
              <div class="img-and-txt-left-top">简单操作</div>
              <div class="img-and-txt-left-bottom">
                简单的点击或者输入，即可对报表进行查询，同时支持不同展示方式的钻取，方便快捷。
              </div>
            </div>
            <div class="img-and-txt-right">
              <img width="89%" src="../../assets/images/medicine_data_a3.png" alt="" />
            </div>
          </div>
          <!-- <div class="h76"></div>
          <div class="img-and-txt">
            <div class="img-and-txt-left">
              <div class="img-and-txt-left-top">易查询</div>
              <div class="img-and-txt-left-bottom">
                简单的点击或者输入，即可对报表进行查询，同时支持不同展示方式的钻取，方便快捷。
              </div>
            </div>
            <div class="img-and-txt-right">
              <img width="89%" src="../../assets/images/medicine_data_a4.png" alt="" />
            </div>
          </div> -->
          <Title titleZh="功能简介" titleEn=""></Title>
          <div class="gnjj-box">
            <div class="gnjj-list">
              <img src="../../assets/images/medicine_data_gnjj1.png" alt="" />
              <div class="gnjj-txt-box">
                <div class="gnjj-title">新增数据源</div>
                <p>添加数据源，支持MYSQL等多种数据库。</p>
              </div>
            </div>
            <div class="gnjj-list">
              <img src="../../assets/images/medicine_data_gnjj2.png" alt="" />
              <div class="gnjj-txt-box">
                <div class="gnjj-title">维度管理</div>
                <p>自定义配置多种维度，并支持数据权限的维度设置。</p>
              </div>
            </div>
            <div class="gnjj-list">
              <img src="../../assets/images/medicine_data_gnjj3.png" alt="" />
              <div class="gnjj-txt-box">
                <div class="gnjj-title">报表配置</div>
                <p>
                  融入了图表、表格、汇总等多种展现形式的报表，并以维度查询或常用条件筛选查询；图表和表格支持钻取以及数据权限统计。
                </p>
              </div>
            </div>
            <div class="gnjj-list">
              <img src="../../assets/images/medicine_data_gnjj4.png" alt="" />
              <div class="gnjj-txt-box">
                <div class="gnjj-title">报表查询</div>
                <p>支持第三方端口引用和访问报表，支付移动端和PC端。</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 智能算法 -->
        <div class="zn-box" v-show="currentIndex == 1">
          <div class="zn-first-box">
            <div class="zn-first-left">
              <div class="zn-first-title">智能派单算法</div>
              <p class="zn-first-txt">
                根据每个省市区业务员的基础信息数据、行为数据、销售数据以及公司的目标值，对业务员推送精准的客户类型以及对应的拜访数。
              </p>
            </div>
            <img src="../../assets/images/medicine_data_zn1.png" alt="" />
          </div>
          <Title
            titleZh="选仓算法"
            titleEn="根据客户的需求数据(即销量数据)以及工厂到各个客户的时间要求、成本要求以及紧急程度等，以时间成本优先原则，利用重心法、P中值等选仓模型选择合适的仓库位置，实现多方共赢。"
            colorZh="#4FA1A4"
            widthEn="730px"
            fontSizeEn="16px"
            marginTopEn="42px"
            lineEn="28px"
          ></Title>
          <img class="zn-img" width="875" src="../../assets/images/medicine_data_zn2.png" alt="" />
          <Title
            titleZh="选品算法"
            titleEn="利用仓库发货数据，根据货品的需求频率和需求稳定度两个维度来进行选品分析，选择需求频率高、需求稳定度高的短尾产品实现前置仓的周转率，减少需求频率极低、需求稳定度不高的长尾产品，降低库存成本。"
            colorZh="#4FA1A4"
            widthEn="730px"
            fontSizeEn="16px"
            marginTopEn="42px"
            lineEn="28px"
          ></Title>
          <img class="zn-img" width="1005" src="../../assets/images/medicine_data_zn3.png" alt="" />
          <Title
            titleZh="销售预测算法"
            titleEn="根据以往的销售数据，通过观察数据的趋势、周期、季节性等因素，利用ARIMA模型预测未来周期的销售数据，以便于仓库的补货。"
            colorZh="#4FA1A4"
            widthEn="730px"
            fontSizeEn="16px"
            marginTopEn="42px"
            lineEn="28px"
          ></Title>
          <img class="zn-img" src="../../assets/images/medicine_data_zn4.png" alt="" />
        </div>
        <!-- 大数据报告 -->
        <div class="dsj-box" v-show="currentIndex == 2">
          <Title
            titleZh="智能化、自动化"
            marginTopZh="52px"
            titleEn="系统通过智能数据分析，自动生成数据报告，定向推送随时下载"
            fontSizeEn="16px"
            marginTopEn="18px"
          ></Title>
          <img class="w100f" src="../../assets/images/medicine_data_dsj1.gif" alt="" />

          <Title
            titleZh="自然语言NLP"
            marginTopZh="52px"
            titleEn="运用自然语言NPL技术无需人工干预，根据数据结果生成高阅读性、高质量的数据报告"
            fontSizeEn="16px"
            marginTopEn="18px"
          ></Title>
          <img class="w100f" src="../../assets/images/medicine_data_dsj2.png" alt="" />

          <Title
            titleZh="数据融合"
            marginTopZh="52px"
            titleEn="高度融合自定义数据处理模块，生成数、表、图，无需人工干预"
            fontSizeEn="16px"
            marginTopEn="18px"
          ></Title>
          <img class="w100f" src="../../assets/images/medicine_data_dsj3.png" alt="" />

          <Title
            titleZh="一客一例"
            marginTopZh="52px"
            titleEn="可按照客户的要求及习惯个性化定义数据报告样式，真正做到一客一例"
            fontSizeEn="16px"
            marginTopEn="18px"
          ></Title>
          <img class="w100f" src="../../assets/images/medicine_data_dsj4.png" alt="" />
        </div>
        <div class="h150"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import mobilePage from '@/views/medicine/mobile/medicine_data.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Box,
    Title,
    Tab,
    mobilePage,
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [{ title: '商业智能BI-SMAS' }, { title: '智能算法' }, { title: '大数据报告' }],
      boxList: [
        {
          url: require('../../assets/images/hospital_logistics_c1.png'),
          title: '搭建院内物流管理体系',
          txt: '传世为医院搭建安全、高效的物流运作系统。通过设置科学的岗位结构，设计高效的作业流程，制定标准的作业规范，构建完善的管理系统体系，助力医院打造国际一流的现代化综合医院。',
        },
        {
          url: require('../../assets/images/hospital_logistics_c2.png'),
          title: '提供中立的院内物流服务',
          txt: '传世通过设置驻院物流服务中心为医院提供全面的物流配送服务。专业的物流作业人员，为医院提供高品质、专业化的物流配送、仓储管理、在库养护、物资拣选、到货验收等服务。',
        },
        {
          url: require('../../assets/images/hospital_logistics_c3.png'),
          title: '延伸院内物流增值服务',
          txt: '传世搭建院内物流管理体系和提供院内物流服务的同时，为医院提供包括物流中心建设和运营、环境保洁、患者及医疗辅助运送、设备运行与维护、用品发放、职工餐与营养餐等在内延伸增值服务。',
        },
        {
          url: require('../../assets/images/hospital_logistics_c4.png'),
          title: '助力打造一流的现代化医院',
          txt: '传世通过搭建院内物流管理系统、提供院内物流服务和延伸增值服务，将医院物流运作水平提升到行业领先，打造完善、科学的物流运营体系，助力医院打造一流的现代化综合医院。',
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 59px;
          color: #ffffff;
          margin-bottom: 22px;
        }
        .font-title-txt1 {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #ffffff;
        }
        .font-title-txt {
          font-size: 18px;
          margin-top: 74px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #ffffff;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    //商业智能
    .img-and-txt {
      width: 1100px;
      height: 583px;
      background: #f5f8f8;
      opacity: 1;
      margin: 0 auto;
      .img-and-txt-left {
        width: 22%;
        margin-left: 3%;
        float: left;
        height: 583px;
        text-align: left;
        .img-and-txt-left-top {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #4fa1a4;
          opacity: 1;
          margin-top: 197px;
        }
        .img-and-txt-left-bottom {
          width: 245px;
          height: 72px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: #3d3d3d;
          opacity: 1;
          margin-top: 33px;
        }
      }
      .img-and-txt-right {
        float: left;
        width: 75%;
        height: 583px;
        img {
          margin-top: 88px;
        }
      }
    }
    //智能算法
    .zn-box {
      .zn-first-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 160px;
        .zn-first-left {
          text-align: left;
          padding-right: 53px;
          .zn-first-title {
            font-size: 32px;
            color: #4fa1a4;
            margin-bottom: 34px;
          }
          .zn-first-txt {
            font-size: 16px;
            line-height: 28px;
            color: #3d3d3d;
            text-indent: 2em;
          }
        }
        img {
          width: 100%;
        }
      }
      .zn-img {
        margin-top: 14px;
      }
    }
    //大数据
    .dsj-box {
      width: 1100px;
      margin: auto;
    }
    //功能简介
    .gnjj-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      .gnjj-list {
        width: 50%;
        display: flex;
        color: #3d3d3d;
        margin-bottom: 132px;
        img {
          width: 88px;
          height: 88px;
        }
        .gnjj-txt-box {
          text-align: left;
          padding-left: 22px;
          .gnjj-title {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
          p {
            width: 390px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
